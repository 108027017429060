<template>
  <v-container>
    <v-row>
      <v-col cols=9>
        <v-file-input v-model="reportInput.value" :label="reportInput.label" prepend-icon="mdi-file-pdf"
          accept="application/pdf" @change="$emit('reportChange')"></v-file-input>
      </v-col>
      <v-col cols=3 class="d-flex justify-right align-center">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FileUploadWithVideo',
  props: ['reportInput'],
  data: () => ({
    showVideo: false
  }),
  methods: {
  }
}
</script>
