<template>
  <v-container>
    <v-card>
      <v-card-title class="accent--text header-font">Account Settings</v-card-title>
      <v-card-text>
        Manage your account settings.
      </v-card-text>
      <v-row class="pt-5">
        <v-col cols=2></v-col>
        <v-col cols=8>
          <v-text-field v-model="userProfile.email" label="Email" disabled="disabled"></v-text-field>
          <v-text-field v-model="newPassword" label="New Password" type="password"></v-text-field>
          <v-text-field v-model="confirmPassword" label="Confirm Password" type="password"></v-text-field>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
      <v-row class="pb-5">
        <v-col cols=2></v-col>
        <v-col cols=8>
          <v-btn color="primary" @click="save" :disabled="saveDisabled">Save</v-btn>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'UserContainer',

  props: ['userProfile'],

  data: () => ({
    newPassword: null,
    confirmPassword: null,
  }),

  computed: {
    passwordMismatch() {
      return this.newPassword && (this.newPassword != this.confirmPassword)
    },
    saveDisabled() {
      return this.passwordMismatch
    }
  },

  methods: {
    save() {
      if (this.newPassword && (this.newPassword == this.confirmPassword)) {
        this.$emit('save', {
          password: this.newPassword
        })

        this.newPassword = null
        this.confirmPassword = null
      }
    }
  }
}
</script>

<style lang="scss">
</style>