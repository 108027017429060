<template>
  <v-card :disabled="disabled">
    <v-card-title class="accent--text header-font">Select Software</v-card-title>
    <v-card-text>
      Choose the software platform you are switching from and the software platform you are switching to.
    </v-card-text>
    <v-container v-if="softwarePlatforms">
      <v-row>
        <v-col cols=5>
          <v-select v-model="oldSoftwarePlatform" :items="oldSoftwarePlatformOptions" label="Old Software"
            @change="$emit('selectOld', oldSoftwarePlatform)">
          </v-select>
        </v-col>
        <v-col cols=2>
          <v-container class="text-center">
            <v-icon color="accent">mdi-arrow-right-thick</v-icon>
          </v-container>
        </v-col>
        <v-col cols=5>
          <v-select v-model="newSoftwarePlatform" :items="newSoftwarePlatformOptions" label="New Software"
            @change="$emit('selectNew', newSoftwarePlatform)">
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'SoftwareSelector',
  props: ['softwarePlatforms', 'disabled'],
  components: {
  },
  mounted() {
    if (this.newSoftwarePlatformOptions.length == 1) {
      this.newSoftwarePlatform = this.newSoftwarePlatformOptions[0].value
      this.$emit('selectNew', this.newSoftwarePlatform)
    }
  },
  data: () => ({
    oldSoftwarePlatform: null,
    newSoftwarePlatform: null
  }),
  computed: {
    oldSoftwarePlatformOptions: function () {
      let options = []
      let platforms = this.softwarePlatforms

      if (platforms && platforms.source_index) {
        options = platforms.source_index.map(function (platform) {
          return {
            text: platforms[platform].display_name,
            value: platform
          }
        }).sort(function (a,b) { return a.text.localeCompare(b.text) })
      }

      return options
    },
    newSoftwarePlatformOptions: function () {
      let options = []
      let platforms = this.softwarePlatforms

      if (platforms && platforms.destination_index) {
        options = platforms.destination_index.map(function (platform) {
          return {
            text: platforms[platform].display_name,
            value: platform
          }
        })
      }

      return options
    }
  },
  methods: {

  }
}
</script>
