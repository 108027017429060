import Vue from 'vue'
import App from './pages/App'
import Login from './pages/Login'
import Profile from './pages/Profile'
import vuetify from './plugins/vuetify'
import axios from './plugins/axios'

Vue.config.productionTip = false

const routes = {
  '/': App,
  '/login': Login,
  '/profile': Profile
}

new Vue({
  vuetify,
  axios,
  created() {
    window.addEventListener('hashchange', () => {
      this.currentRoute = window.location.hash.slice(1)
      console.info(`routing to ${this.currentRoute}`)
    })
  },
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent () {
      return routes[this.currentRoute] || App
    }
  },
  render (h) { return h(this.ViewComponent) }
}).$mount('#app')

