<template>
  <v-card :loading="loading">
    <v-card-title class="accent--text header-font">Convert</v-card-title>
    <v-card-text>
      Your import files will be available for download when the conversion is complete.
    </v-card-text>
    <v-container>
      <v-row>
        <v-col cols=2></v-col>
        <v-col cols=8>
          <v-list-item v-for="fileConversion in fileConversions" :key="fileConversion.name">
            <v-list-item-avatar v-if="!fileConversion.availableForDownload">
              <div v-if="fileConversion.failed">
                <v-icon color="red">mdi-alert</v-icon>
              </div>
              <div v-else>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon color="green">mdi-check-bold</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ fileConversion.label }}
            </v-list-item-title>
            <v-list-item-action>
              <v-btn :disabled="!fileConversion.availableForDownload" color="primary" :href="fileConversion.downloadUrl"
                download><v-icon left>mdi-cloud-download</v-icon>Download</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

export default {
  name: 'ConversionContainer',
  props: ['conversionBatch', 'reportFiles', 'loading'],

  computed: {
    fileConversions: function () {
      let fileConversions = []

      if (this.conversionBatch) {
        fileConversions = this.conversionBatch.file_conversions.map((fileConversion) => {
          let reportFile = this.reportFiles.filter((r) => { return r.name == fileConversion.conversion_key })[0]
          let status = fileConversion.status.charAt(0).toUpperCase() + fileConversion.status.slice(1)
          let url = process.env.VUE_APP_API_HOST + '/api/file-conversions/' + fileConversion.reference_id + '/destination-import-file'

          return {
            name: reportFile.name,
            label: reportFile.label,
            status: status,
            availableForDownload: fileConversion.destination_attached == true,
            failed: fileConversion.status == 'failed',
            failureDetails: JSON.parse(fileConversion.failure_details),
            downloadUrl: url
          }
        })
      }

      return fileConversions
    }
  }
}
</script>
