<template>
  <v-card>
    <v-container>
      <v-row class="pt-5">
        <v-col cols=2>
        </v-col>
        <v-col cols=8>
          <v-alert type="error" v-if="displayError">Sorry, your credentials are incorrect. Please check your email and
            password, and try
            again.</v-alert>
          <v-text-field v-model="email" label="Email" @keyup.enter="login"></v-text-field>
          <v-text-field v-model="password" label="Password" type="password" @keyup.enter="login"></v-text-field>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
      <v-row class="pb-5">
        <v-col cols=2></v-col>
        <v-col cols=8>
          <v-btn :disabled="disabled" color="primary" @click="login">Login</v-btn>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
// import { pick } from 'lodash'

export default {
  name: 'LoginContainer',
  props: [],
  data: () => ({
    email: null,
    password: null,
    displayError: false,
    loading: false
  }),
  computed: {
    credentials: function () {
      return {
        user: {
          email: this.email,
          password: this.password
        }
      }
    },
    disabled: function () {
      return !(this.email && this.password) && !this.loading
    }
  },
  methods: {
    async login() {
      if (this.disabled) {
        return
      }

      try {
        this.displayError = false
        this.loading = true
        const response = await Vue.axios.post(process.env.VUE_APP_API_HOST + '/login', {
          user: {
            email: this.email,
            password: this.password,
          }
        })

        const session = {
          user: response.data,
          authorization: response.headers.authorization
        }

        this.$emit('login', session)
      } catch (_e) {
        this.displayError = true
      }
    }
  }
}
</script>
