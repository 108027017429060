<template>
  <v-card :disabled="disabled">
    <v-card-title class="accent--text header-font">Select Software Type</v-card-title>
    <v-card-text>
      Choose the type of software that you are switching.
    </v-card-text>
    <v-container>
      <v-row>
        <v-col cols=2></v-col>
        <v-col cols=8>
          <v-radio-group v-model="selectedType" @change="$emit('select', selectedType)">
            <v-radio v-for="typeRadio in typeRadios" :key="typeRadio.key" :label="typeRadio.label"
              :value="typeRadio.value"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'TypeSelector',
  props: ['softwareTypes', 'disabled'],
  mounted() {
    if (this.softwareTypes.index.length == 1) {
      this.selectedType = this.typeRadios[0].value
      this.$emit('select', this.selectedType)
    }
  },
  data: () => ({
    selectedType: null
  }),
  computed: {
    typeIndex: function () {
      return this.softwareTypes.index
    },
    typeRadios: function () {
      let radios = []
      let types = this.softwareTypes

      if (this.typeIndex) {
        radios = this.typeIndex.map((type) => {
          return {
            key: type,
            value: type,
            label: types[type].display_name
          }
        })
      }
      return radios
    }
  }
}
</script>
