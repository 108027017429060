<template>
  <main-layout>
  <v-container>
    <v-container v-if="softwareTypes">
      <TypeSelector v-if="showSoftwareTypes" :softwareTypes="softwareTypes" @select="onSoftwareTypeSelected"
        :disabled="converting" />
    </v-container>
    <v-container v-else>
      <v-card>
        <v-container class="d-flex justify-center align-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>
      </v-card>
    </v-container>
    <transition name="slide-fade">
      <v-container v-if="softwarePlatforms">
        <SoftwareSelector :softwarePlatforms="softwarePlatforms" @selectOld="onOldSoftwarePlatformSelected"
          @selectNew="onNewSoftwarePlatformSelected" :disabled="converting" />
      </v-container>
    </transition>
    <transition name="slide-fade">
      <v-container v-if="reportsToUpload.length">
        <ReportsUploader :reports="reportsToUpload" @reportChange="onReportUploaderChanged"
          :disabled="converting" />
      </v-container>
    </transition>
    <transition name="slide-fade">
      <v-container v-if="converting">
        <ConversionContainer :conversionBatch="conversionBatch" :reportFiles="reportFiles"
          :loading="!conversionComplete" />
      </v-container>
      <v-container v-else>
        <v-container v-if="formCompleted" class="d-flex justify-center">
          <v-btn dark color="primary" @click="convert"><v-icon left>mdi-cloud-upload</v-icon> Convert</v-btn>
        </v-container>
      </v-container>
    </transition>
  </v-container>
  </main-layout>
</template>

<script>
import TypeSelector from '../components/TypeSelector.vue'
import SoftwareSelector from '../components/SoftwareSelector'
import ReportsUploader from '../components/ReportsUploader'
import ConversionContainer from '../components/ConversionContainer'
import MainLayout from '../layouts/Main'
import Vue from 'vue'

export default {
  name: 'App',

  created: function () {
    if (localStorage.sessionToken) {
      console.info('Loading existing session.')

      this.sessionToken = localStorage.sessionToken
      this.fetchConversionMap()
    } else {
      console.info('not logged in....')
      window.location.href = '#/login'
    }
  },

  components: {
    TypeSelector,
    SoftwareSelector,
    ReportsUploader,
    ConversionContainer,
    MainLayout
  },

  data: () => ({
    conversionMap: {},
    selectedSoftwareType: null,
    oldSoftwarePlatform: null,
    newSoftwarePlatform: null,
    reportFiles: [],
    uploadedFiles: [],
    formCompleted: false,
    converting: false,
    conversionBatch: null,
    conversionComplete: false,
    sessionToken: null,
  }),

  computed: {
    showSoftwareTypes() {
      return this.conversionMap.types.index.length > 1
    },
    softwareTypes: function () {
      return this.conversionMap.types
    },
    softwarePlatforms: function () {
      let platforms = null

      if (this.selectedSoftwareType) {
        platforms = this.softwareTypes[this.selectedSoftwareType].platforms
      }

      return platforms
    },
    selectedPlatform: function () {
      let platform = null

      if (this.selectedSoftwareType && this.oldSoftwarePlatform && this.newSoftwarePlatform) {
        platform = this.conversionMap.types[this.selectedSoftwareType].platforms[this.oldSoftwarePlatform]
      }

      return platform
    },
    reportsToUpload: function () {
      let reports = []
      let selectedSoftwareType = null

      if (this.selectedPlatform) {
        selectedSoftwareType = this.conversionMap.types[this.selectedSoftwareType]

        reports = this.selectedPlatform.reports.map(function (report) {
          let reportToUpload = selectedSoftwareType.reports[report]
          reportToUpload.name = report

          return reportToUpload
        })
      }

      return reports;
    },
    authenticated: function () {
      return !!this.sessionToken
    }
  },

  methods: {
    logout() {
      this.sessionToken = null
      localStorage.sessionToken = null
      this.resetData()

      window.location.href = '#/login'
    },
    async fetchConversionMap() {
      if (this.authenticated) {
        const url = process.env.VUE_APP_API_HOST + "/api/conversion-map"
        const config = {
          headers: { "Authorization": this.sessionToken }
        }

        try {
          const response = await Vue.axios.get(url, config)

          this.initializeData(response.data)
        } catch (e) {
          if (e.response.status == "401") {
            console.warn('User was not authenticated. Logging out.')
            this.logout()
          } else {
            console.error(e)
          }
        }
      }
    },
    initializeData(data) {
      this.conversionMap = data['conversion_map']
      if (this.conversionMap.types.index.length == 1) {
        this.selectedSoftwareType = this.conversionMap.types.index[0]
      }
    },
    resetData() {
      this.conversionMap = {}
      this.selectedSoftwareType = null
      this.oldSoftwarePlatform = null
      this.newSoftwarePlatform = null
      this.reportFiles = []
      this.uploadedFiles = []
      this.formCompleted = false
      this.converting = false
      this.conversionBatch = null
      this.conversionComplete = false
      this.sessionToken = null
    },
    onSoftwareTypeSelected(selectedSoftwareType) {
      this.selectedSoftwareType = selectedSoftwareType
    },
    onOldSoftwarePlatformSelected(oldSoftwarePlatform) {
      this.oldSoftwarePlatform = oldSoftwarePlatform
    },
    onNewSoftwarePlatformSelected(newSoftwarePlatform) {
      this.newSoftwarePlatform = newSoftwarePlatform
    },
    onReportUploaderChanged(reportFiles) {
      this.reportFiles = reportFiles
      this.checkForCompletedForm()
    },
    checkForCompletedForm() {
      let filesArray = this.reportFiles.map((r) => r.value)
      if (filesArray.some((f) => f != null)) {
        this.formCompleted = true
      } else {
        this.formCompleted = false
      }
    },
    convert() {
      if (this.formCompleted) {
        this.converting = true
        this.createConversionBatch()
      }
    },
    createConversionBatch() {
      const config = {
        headers: { "Authorization": this.sessionToken }
      }
      Vue.axios.post(process.env.VUE_APP_API_HOST + '/api/conversion-batches', {
        conversion_batch: {
          platform_type: this.selectedSoftwareType,
          source_platform: this.oldSoftwarePlatform,
          destination_platform: this.newSoftwarePlatform
        }
      }, config).then((response) => {
        this.conversionBatch = response.data.object
        this.createFileConversions()
      }).catch((e) => {
        if (e.response.status == "401") {
          console.warn('User was not authenticated. Logging out.')
          this.logout()
        } else {
          console.error(e)
        }
      })
    },
    createFileConversions() {
      let conversionBatch = this.conversionBatch
      const config = {
        headers: { "Authorization": this.sessionToken }
      }

      this.reportFiles.forEach((reportFile) => {
        if (reportFile.value) {
          Vue.axios.post(process.env.VUE_APP_API_HOST + '/api/file-conversions', {
            file_conversion: {
              conversion_batch_id: conversionBatch.id,
              conversion_key: reportFile.name
            }
          }, config).then((response) => {
            let fileConversion = response.data.object
            this.uploadFile(fileConversion, reportFile)
          }).catch((error) => {
            console.error(error)
          })
        }
      })
    },
    uploadFile(fileConversion, reportFile) {
      let formData = new FormData()
      let url = process.env.VUE_APP_API_HOST + '/api/file-conversions/' + fileConversion.id + '/upload'

      formData.append('file', reportFile.value)
      Vue.axios.patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": this.sessionToken
        }
      }).then(() => {
        this.checkFileUploads(fileConversion)
      }).catch((e) => {
        if (e.response.status == "401") {
          console.warn('User was not authenticated. Logging out.')
          this.logout()
        } else {
          console.error(e)
        }
      })
    },
    checkFileUploads(fileConversion) {
      this.uploadedFiles.push(fileConversion)

      if (this.reportFiles.filter((f) => f.value != null).length == this.uploadedFiles.length) {
        this.convertFiles(fileConversion.conversion_batch_id)
      }
    },
    convertFiles(conversionBatchId) {
      let url = process.env.VUE_APP_API_HOST + '/api/conversion-batches/' + conversionBatchId + '/convert'
      const config = {
        headers: { "Authorization": this.sessionToken }
      }

      Vue.axios.patch(url, {}, config).then(() => {
        this.pollConversionBatch()
      }).catch((e) => {
        if (e.response.status == "401") {
          console.warn('User was not authenticated. Logging out.')
          this.logout()
        } else {
          console.error(e)
        }
      })
    },
    pollConversionBatch() {
      this.reloadConversionBatch(this.checkConversionStatus)
    },
    reloadConversionBatch(callback) {
      let url = process.env.VUE_APP_API_HOST + '/api/conversion-batches/' + this.conversionBatch.id
      const config = {
        headers: { "Authorization": this.sessionToken }
      }

      Vue.axios.get(url, config).then((response) => {
        this.conversionBatch = response.data.object
        if (callback) { callback() }
      }).catch((e) => {
        if (e.response.status == "401") {
          console.warn('User was not authenticated. Logging out.')
          this.logout()
        } else {
          console.error(e)
        }
      })
    },
    checkConversionStatus() {
      let fileConversions = this.conversionBatch.file_conversions

      if (fileConversions) {
        let pending = fileConversions.filter((fileConversion) => {
          return !(fileConversion.status == 'completed') && !(fileConversion.status == 'failed')
        })

        if (pending.length > 0) {
          setTimeout(this.pollConversionBatch, 2000)
        } else {
          this.conversionComplete = true;
        }
      } else {
        setTimeout(this.pollConversionBatch, 2000)
      }
    }
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all .4s ease;
}

.logo {
  height: 35px;
}

.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(15px);
  opacity: 0;
}
</style>
