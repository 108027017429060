<template>
  <main-layout>
    <v-container>
      <v-row>
        <v-col cols=12> <v-alert type="success" v-if="updateSuccess">Your password has been updated.</v-alert></v-col>
      </v-row>
      <UserContainer :userProfile="userProfile" @save="saveUserProfile" />
    </v-container>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/Main'
import UserContainer from '../components/UserContainer'
import Vue from 'vue'

export default {
  name: 'Profile',

  created: function () {
    if (localStorage.sessionToken) {
      console.info('Loading existing session.')

      this.sessionToken = localStorage.sessionToken
      this.fetchUserProfile()
    } else {
      console.info('not logged in....')
      window.location.href = window.location.href + 'login'
    }
  },

  components: {
    MainLayout,
    UserContainer
  },

  data: () => ({
    sessionToken: null,
    userProfile: { email: null },
    updateSuccess: false
  }),

  computed: {
    authenticated: function () {
      return !!this.sessionToken
    }
  },

  methods: {
    async fetchUserProfile() {
      if (this.authenticated) {
        const url = process.env.VUE_APP_API_HOST + "/api/user-profiles/current"
        const config = {
          headers: { "Authorization": this.sessionToken }
        }

        try {
          const response = await Vue.axios.get(url, config)

          this.initializeData(response.data.object)
        } catch (e) {
          if (e.response.status == "401") {
            console.warn('User was not authenticated. Logging out.')
            this.logout()
          } else {
            console.error(e)
          }
        }
      }
    },

    initializeData(data) {
      console.info(data)
      this.userProfile = data
    },

    saveUserProfile(userProfile) {
      const config = {
        headers: { "Authorization": this.sessionToken }
      }

      Vue.axios.patch(process.env.VUE_APP_API_HOST + '/api/user-profiles/update', {
        user_profile: {
          password: userProfile.password
        }
      }, config).then((response) => {
        console.info(response)

        this.updateSuccess = true

        setTimeout(()=>{
          this.updateSuccess = false
        }, 5000)
      }).catch((error) => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss">
</style>