<template>
  <v-app :style="{ backgroundColor: '#f6f7f9' }">
    <v-container class="white black--text" v-if="authenticated">
      <v-app-bar app color="primary" dark>
        <v-toolbar-title><a href="/"><img src="../assets/LogoLight.svg" class="mt-2 logo"></a></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="convert" v-if="authenticated">Convert</v-btn>
        <v-btn text @click="profile" v-if="authenticated">Account Settings</v-btn>
        <v-btn text @click="logout" v-if="authenticated">Logout</v-btn>
      </v-app-bar>
      <v-main>
        <slot></slot>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Main',

  created: function () {
    if (localStorage.sessionToken) {
      console.info('Loading existing session.')

      this.sessionToken = localStorage.sessionToken
    } else {
      console.info('Existing session did not exist.')

      window.location.href = '#/login'
    }
  },

  components: {
  },

  data: () => ({
    sessionToken: null,
  }),

  computed: {
    authenticated: function () {
      return !!this.sessionToken
    }
  },

  methods: {
    logout() {
      this.sessionToken = null
      localStorage.sessionToken = null

      window.location.href = '#/login'
    },
    profile() {
      window.location.href = '#/profile'
    },
    convert() {
      window.location.href = '#/'
    }
  },
};
</script>

<style lang="scss">
.header-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  background-color: "#f6f7f9";
}

.logo {
  height: 35px;
}
</style>
