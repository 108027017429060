<template>
  <v-app :style="{ backgroundColor: '#f6f7f9' }">
    <v-container>
      <v-row>
        <v-col cols=2></v-col>
        <v-col cols=8 class="d-flex justify-center flex-column">
          <img src="../assets/LogoRegular.svg" class="login-logo mt-16 mb-16">
          <LoginContainer @login="login" />
        </v-col>
        <v-col cols=2></v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LoginContainer from '../components/LoginContainer'

export default {
  name: 'Login',

  components: {
    LoginContainer
  },

  methods: {
    login(session) {
      this.sessionToken = session.authorization
      localStorage.sessionToken = this.sessionToken

      window.location.href = "#/"
    },
    logout() {
      this.sessionToken = null
      localStorage.sessionToken = null
      this.resetData()

      window.location.href = '#/login'
    },
  }
}

</script>


<style lang="scss">
.app {
  background-color: "#f6f7f9";
}

.login-logo {
  height: 55px;
}
</style>