export default {
  primary: {
    base: "#000A3C"
  },
  secondary: {
    base: "#C3C6D6"
  },
  accent: {
    base: "#007FBE"
  },
  error: {
    base: "#B6002A"
  },
  info: {
    base: "#C3C6D6"
  },
  success: {
    base: "#00BE48"
  },
  warning: {
    base: "#B63100"
  },
  white: {
    base: "#F6F7F9"
  },
  black: {
    base: "#181A24"
  },
}
